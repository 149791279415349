<template>
  <div class="SopCollapseItem" :class="{ open: isOpen }">
    <div class="SopCollapseItem__header" @click="toggleCollapse" :class="{ disabled: isEmptyContent }">
      <font-awesome-icon class="text-primary" :icon="['far', 'arrow-progress']" />
      <div class="SopCollapseItem__header--text-block">
        <div v-if="!isIntroOrConclusion" class="SopCollapseItem__header--index">
          {{ $t("all.step") }} {{ String(index).padStart(2, "0") }}
        </div>
        <div class="SopCollapseItem__header--title">{{ item.step || "N/A" }}</div>
      </div>
      <button class="SopCollapseItem__header--toggle">
        <font-awesome-icon :class="{ 'fa-rotate-90': isOpen }" :icon="['far', 'chevron-right']" />
      </button>
    </div>
    <div class="SopCollapseItem__content">
      <div class="indent">{{ item.description || "N/A" }}</div>
      <template v-if="!isIntroOrConclusion">
        <div class="SopCollapseItem__subItem" v-if="item.tool">
          <div class="title-row">
            <font-awesome-icon class="text-primary" :icon="['fas', 'screwdriver-wrench']" />
            <label class="title">Tools Needed</label>
          </div>
          <div class="indent">{{ item.tool }}</div>
        </div>
        <div class="SopCollapseItem__subItem" v-if="item.safetyOrWarning">
          <div class="title-row">
            <font-awesome-icon class="text-warning" :icon="['far', 'triangle-exclamation']" />
            <label class="title">Safety Warnings</label>
          </div>
          <div class="indent">{{ item.safetyOrWarning }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "SopCollapseItem",
  props: {
    item: Object,
    index: Number,
    total: Number,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isEmptyContent() {
      if (this.isIntroOrConclusion) return this.item.description === "";
      return this.item.safetyOrWarning === "" && this.item.description === "" && this.item.tool === "";
    },
    isIntroOrConclusion() {
      return this.index === 0 || this.total - 1 === this.index;
    },
  },
  methods: {
    toggleCollapse() {
      if (this.isEmptyContent) {
        return;
      }
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped lang="scss">
.SopCollapseItem {
  border-radius: 8px;
  background: var(--dGrey2-color);
  text-align: left;
  font-size: 16px;
  &.open > &__header {
    padding: var(--spacing-06) var(--spacing-06) var(--spacing-05);
  }
  &.open > &__content {
    display: flex;
  }
  &__header {
    cursor: pointer;
    padding: var(--spacing-06);
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--dGrey6-color);
    font-weight: 500;
    gap: 16px;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
    &--text-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      gap: 8px;
    }
    &--index {
      text-transform: capitalize;
      outline: none;
      line-height: 100%;
    }
    &--title {
      color: white;
      user-select: none;
      line-height: 100%;
    }
    &--toggle {
      width: 40px;
      height: 40px;
      text-align: right;
    }
  }

  &__content {
    color: #bcc0cb;
    display: none;
    flex-direction: column;
    padding: 0 var(--spacing-06) var(--spacing-06);
    gap: var(--spacing-06);
    white-space: pre-line;
    .indent {
      padding-left: 36px;
    }
  }

  &__subItem {
    width: 100%;
    .title-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
    }
    .title {
      color: white;
      margin-left: 16px;
      font-size: 20px;
    }
  }
}
</style>
