<template>
  <div
    class="AttachmentBtnBase text-no-wrap"
    :class="{
      'AttachmentBtnBase--large': large,
      'AttachmentBtnBase--mobile': isSmallToolboxSize,
      'AttachmentBtnBase--currentBtn': isActive,
      'AttachmentBtnBase--disabled': isDisabled,
    }"
    @click="$emit('click')"
  >
    <slot name="icon"></slot>
    <span v-if="!isSmallToolboxSize" class="AttachmentBtnBase__text">
      <slot name="text"></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "AttachmentBtnBase",
  props: {
    isActive: Boolean,
    isDisabled: Boolean,
    large: Boolean,
    isSmallToolboxSize: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.AttachmentBtnBase {
  height: 24px;
  padding: 0 12px;
  margin-right: 8px;
  border-radius: 12px;
  border: 1px solid #9397a6;
  color: #9397a6;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 12px;
  cursor: pointer;
  &__text {
    margin-left: 4px;
  }
  &:hover {
    background: rgba(80, 80, 80, 0.2);
    border: 1px solid #4689f3;
  }
  &--large {
    height: 32px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 16px;
  }
  &--mobile {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    padding: 0 0 0 8px;
    margin-right: 0;
    ::v-deep svg {
      height: 15px;
      width: 15px;
    }
  }
  &--currentBtn {
    color: #4689f3;
    border: 1px solid #4689f3;
    background: rgba(70, 137, 243, 0.2);
  }
  &--disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
</style>
