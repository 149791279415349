<template>
  <div class="CommentCardEdit">
    <div class="CommentCardEdit__top text-no-wrap">
      <d-avatar :size="32" :userId="comment.uid" />
      <span class="CommentCardEdit__top--name text-truncate">{{ comment.displayName }}</span>
      <span class="CommentCardEdit__top--dot"></span>
      <span class="CommentCardEdit__top--time">
        <span v-if="isUpdating">{{ $t("player.updating") }}</span>
        <span v-else>{{ timeSince }}</span>
      </span>
      <v-spacer></v-spacer>
    </div>

    <div class="CommentCardEdit__edit">
      <textarea
        v-model.trim="commentInput"
        rows="1"
        ref="commentInput"
        :placeholder="$t('player.writeAComment')"
        :disabled="isUpdating"
        @focus="setIsStartTyping()"
        @blur="setIsEndTyping()"
        @input="autoSetTextareaHeight()"
      ></textarea>
      <div v-if="commentInput.length > maxCharacters" class="CommentCardEdit__errorText">
        {{ $tc("rules.numMaxCharacters", this.maxCharacters) }}
      </div>
    </div>

    <div class="CommentCardEdit__btns">
      <d-button
        dark
        depressed
        round
        small
        color="#454545"
        class="text-capitalize mb-0 mr-1 mt-3"
        :disabled="isUpdating"
        light
        @click="clearSelectedComment()"
        >{{ $t("all.cancel") }}</d-button
      >
      <d-button
        dark
        depressed
        round
        small
        color="#4689F3"
        class="white--text text-capitalize mr-0 mb-0 mt-3"
        light
        :disabled="disableSaveBtn"
        @click="updateComment()"
      >
        <v-progress-circular v-if="isUpdating" size="12" width="1" class="mr-1" indeterminate></v-progress-circular>
        {{ $t("all.save") }}
      </d-button>
    </div>
  </div>
</template>

<script>
import DAvatar from "@/components/ui_components/DAvatar.vue";
import { formatIsoStrTimeSince } from "@/js/time/timeSince.js";
import { updateCommentByCommentId } from "@/server/api-server.js";
import { mapActions } from "vuex";

export default {
  name: "CommentCardEdit",
  components: {
    DAvatar,
  },
  props: {
    comment: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      timeSince: "",
      commentInput: "",
      isUpdating: false,
      maxCharacters: 1000,
    };
  },
  created() {
    this.commentInput = this.comment.comment;
    this.timeSince = formatIsoStrTimeSince({
      isoStr: this.comment.createdAt,
      vueInstanceRef: this,
      simplify: true,
    });
    this.$nextTick(() => {
      this.autoSetTextareaHeight();
      this.$refs.commentInput.focus();
    });
  },
  methods: {
    ...mapActions("workflowComments", ["updateCommentInCommentsData", "clearSelectedComment"]),
    ...mapActions("workflowPlayer", ["setIsStartTyping", "setIsEndTyping"]),
    async updateComment() {
      if (!this.commentInput || this.commentInput === this.comment.comment || this.isUpdating) return;
      this.isUpdating = true;
      const commentId = this.comment.id;
      let comment = this.commentInput;
      const params = {
        commentId,
        comment,
      };
      const { ok, errorMessage } = await updateCommentByCommentId(params);
      if (ok) {
        let newComment = this.comment;
        newComment.comment = comment;
        const currentDate = new Date();
        const timestamp = currentDate.toISOString();
        newComment.updatedAt = timestamp;
        this.updateCommentInCommentsData({ updatedCommentId: commentId, newComment });
      } else {
        console.error(errorMessage);
      }
      this.isUpdating = false;
    },
    autoSetTextareaHeight() {
      const ref = "commentInput";
      this.$refs[ref].style.height = "auto";
      const textareaHeight = this.$refs[ref].scrollHeight;
      this.$refs[ref].style.height = textareaHeight + "px";
    },
  },
  computed: {
    disableSaveBtn() {
      return (
        !this.commentInput ||
        this.isUpdating ||
        this.comment.comment === this.commentInput ||
        this.commentInput.length > this.maxCharacters
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.CommentCardEdit {
  position: relative;
  padding-bottom: 8px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  &__top {
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &--name {
      max-width: 45%;
      margin-left: 8px;
      text-overflow: ellipsis;
    }
    &--dot {
      height: 3px;
      width: 3px;
      background-color: #9397a6;
      border-radius: 50%;
      margin-left: 8px;
      margin-right: 8px;
    }
    &--time {
      color: #9397a6;
      font-size: 12px;
    }
  }
  &__edit {
    background: #121314;
    border-radius: 4px;
    padding: 12px 16px 8px 16px;
    textarea {
      pointer-events: auto;
      padding: 0;
      width: 100%;
      resize: none;
      overflow-y: auto;
    }
    textarea::placeholder {
      color: #919191;
    }
    textarea:focus {
      outline: none;
    }
  }
  &__errorText {
    position: relative;
    padding: 4px 0 0 0;
    color: #888888;
    font-size: 12px;
    line-height: 14px;
  }
  &__btns {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
