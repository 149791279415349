<template>
  <div class="PlayerStepsMain">
    <div class="PlayerStepsMain__list">
      <loading-message transition :show="isLoading" />
      <div v-for="(step, index) in getters_steps" :key="step.id" @click="handleChangeStep({ index })">
        <player-step-card
          :step="step"
          :isCurrentStep="getter_current_step_idx === index"
          :index="index"
          :isSmallCard="isSmallToolboxSize"
        />
      </div>
    </div>
    <div v-if="isShowQuiz" class="PlayerStepsMain__bottom">
      <player-quiz-card layout-mode="row" />
    </div>
  </div>
</template>

<script>
import LoadingMessage from "@/components/DWorkflowPlayer/toolbox/LoadingMessage.vue";
import PlayerStepCard from "@/components/DWorkflowPlayer/steps/step/PlayerStepCard.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PlayerStepsMain",
  components: {
    PlayerQuizCard: () => import("@/components/DWorkflowPlayer/steps/step/PlayerQuizCard.vue"),
    LoadingMessage,
    PlayerStepCard,
  },
  props: {
    workflowId: String,
    isSmallToolboxSize: Boolean,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("workflowPlayer", ["setCurrentStepIdx"]),
    handleChangeStep(payload) {
      this.setCurrentStepIdx(payload);
    },
  },
  computed: {
    ...mapGetters("workflowPlayer", [
      "getters_steps",
      "getter_current_step_idx",
      "getter_is_repeat_step",
      "getter_is_show_quiz",
    ]),
    ...mapGetters("permission", ["getter_quiz_enabled"]),
    isShowQuiz() {
      if (this.$route.params.isPreviewVideoMode) return false;
      return this.getter_quiz_enabled && this.getter_is_show_quiz;
    },
  },
};
</script>

<style lang="scss" scoped>
.PlayerStepsMain {
  position: relative;
  min-height: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 960px) {
    width: 100%;
  }
  &__list {
    position: relative;
    height: 100%;
    overflow: auto;
  }
  &__bottom ::v-deep .PlayerQuizCard {
    margin: 0;
  }
}
</style>
