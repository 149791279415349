<template>
  <div :class="{ PlayerProcessedVideoDivideLayout: isDivideMode }">
    <div v-if="isDivideMode" class="PlayerProcessedVideoDivideLayout__header">
      <div class="text-truncate">{{ workflowTitle }}</div>
      <button @click="handleClose">
        <font-awesome-icon :icon="['far', 'xmark']" />
      </button>
    </div>
    <div :class="{ PlayerProcessedVideoDivideLayout__content: isDivideMode }">
      <div :class="{ PlayerProcessedVideoDivideLayout__left: isDivideMode }">
        <div :class="{ 'PlayerProcessedVideoDivideLayout__video-wrapper': isDivideMode }">
          <slot />
        </div>
      </div>
      <div class="PlayerProcessedVideoDivideLayout__divider" />
      <div v-if="isDivideMode" class="PlayerProcessedVideoDivideLayout__right">
        <player-toolbox :workflowId="workflowData.id" :isPublicPlayerMain="isPublic" />
      </div>
    </div>
  </div>
</template>
<script>
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { mapState, mapActions } from "vuex";
import PlayerToolbox from "@/components/DWorkflowPlayer/toolbox/PlayerToolbox.vue";
export default {
  name: "PlayerProcessedVideoDivideLayout",
  components: {
    PlayerToolbox,
  },
  props: {
    subtitlesChoice: {
      type: String,
    },
    isPublic: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState("workflowPlayer", ["isDivideMode", "workflowData"]),
    workflowTitle() {
      const displayLang = this.subtitlesChoice || this.$i18n.locale;
      return getDisplayTitle(this.workflowData, displayLang);
    },
  },
  methods: {
    ...mapActions("workflowPlayer", ["setIsDivideMode"]),
    handleClose() {
      this.setIsDivideMode(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.PlayerProcessedVideoDivideLayout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: var(--dGrey1-color);
  padding: 0 32px;
  @include pad {
    padding: 0 16px;
  }
  &__header {
    flex-shrink: 0;
    padding: 16px 0;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    line-height: 100%;
    color: white;
    @include pad {
      padding: 8px 0;
      font-size: 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow: hidden;
    flex: 1;
  }
  &__divider {
    height: 100%;
    width: 1px;
    background: var(--dGrey3-color);
  }
  &__left {
    position: relative;
    width: 55%;
    padding-top: 16px;
  }

  &__video-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; // 16:9
    height: 0;
    overflow: hidden;
    border-radius: 8px;
  }

  &__right {
    width: 45%;
    padding-bottom: 8px;
  }
}
</style>
