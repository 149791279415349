<template>
  <div
    class="PlayerAttachmentsButton"
    @mouseenter="mouseoverBtn = true"
    @mouseleave="mouseoverBtn = false"
    @click.stop="clickToShowAttachments"
  >
    <span class="text-no-wrap">
      <icon-base width="14" height="14" class="PlayerAttachmentsButton__icon"> <d-icon-attachment /> </icon-base>
      {{ attachmentNumDisplay }}
    </span>
    <div class="PlayerAttachmentsButton__text" :class="{ 'PlayerAttachmentsButton__text--show': showFullText }">
      {{ attachmentText }}
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase";
import DIconAttachment from "@/components/icons/player/DIconAttachment.vue";
import { getNumberWithZero } from "@/js/common/formatText.js";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "PlayerAttachmentsButton",
  props: {
    upper: Boolean,
    currentStep: Number,
    showList: Boolean,
    isMobileDevice: Boolean,
  },
  data() {
    return {
      mouseoverBtn: false,
    };
  },
  components: { IconBase, DIconAttachment },
  methods: {
    ...mapActions("workflowPlayer", ["setSelectedAttachmentStep", "openAttachmentsPopup"]),
    setCurrentStep() {
      let attachmentStep = this.getters_steps[this.currentStep];
      attachmentStep["stepIndex"] = this.currentStep;
      this.setSelectedAttachmentStep({ step: attachmentStep });
    },
    clickToShowAttachments() {
      if (this.getter_is_window_mode) {
        this.setCurrentStep();
      } else {
        if (this.showList) {
          this.$emit("hide-list");
        } else {
          this.$emit("show-list");
        }
      }

      // TODO: Parsing attachment type to text, then track event by Heap.
      // const type = this.getter_attachments[this.currentStep][0].type;
      // const attachment = ATTACHMENT_TYPE[type] || "Other";
    },
  },
  computed: {
    ...mapState("workflowPlayer", ["isFullscreen"]),
    ...mapGetters("workflowPlayer", ["getters_steps", "getter_attachments", "getter_is_window_mode"]),
    showFullText() {
      return this.mouseoverBtn || this.showList || this.isFullscreen;
    },
    attachmentNum() {
      let num = 0;
      const attachments = this.getters_steps[this.currentStep]
        ? this.getters_steps[this.currentStep].attachments
        : null;
      if (!attachments) {
        return num;
      }
      Object.values(attachments).forEach((attachment) => {
        num = num + attachment.length;
      });
      return num;
    },
    attachmentNumDisplay() {
      return getNumberWithZero({ num: this.attachmentNum });
    },
    attachmentText() {
      return this.attachmentNum > 1 ? this.$t("all.attachments") : this.$t("all.attachment");
    },
  },
};
</script>

<style lang="scss" scoped>
.PlayerAttachmentsButton {
  z-index: 2;
  position: relative;
  height: 32px;
  padding: 4px 12px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #4689f3;
  border-radius: 16px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  transition: 0.2s;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  pointer-events: auto;
  width: fit-content;

  &__icon {
    position: relative;
    margin-bottom: -1px;
    margin-right: 6px;
  }
  &__text {
    padding-left: 4px;
    max-width: 0;
    overflow: hidden;
    transition: 0.3s;
    &--show {
      max-width: 200px;
      transition: 0.3s;
    }
  }
}
</style>
