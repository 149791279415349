<template>
  <div class="CommentCardDelete">
    <div class="CommentCardDelete__title mb-1">{{ $t("player.deleteComment") }}</div>
    <div v-html="$t('player.deleteCommentMsg')"></div>
    <div class="CommentCardDelete__btns">
      <d-button
        dark
        depressed
        round
        small
        color="#454545"
        class="text-capitalize mb-0 mr-1"
        @click="clearSelectedComment()"
        >{{ $t("all.cancel") }}</d-button
      >
      <d-button
        dark
        depressed
        round
        small
        color="#FC585B"
        class="white--text text-capitalize mr-0 mb-0"
        :disabled="isDeleting"
        @click="confirmDeleteComment()"
        >{{ $t("all.delete") }}</d-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { deleteCommentByCommentId } from "@/server/api-server.js";

export default {
  name: "CommentCardDelete",
  components: {},
  props: {
    comment: {
      type: Object,
      require: true,
    },
  },
  data() {
    return { isDeleting: false };
  },
  methods: {
    ...mapActions("workflowComments", ["deleteCommentInCommentsData", "clearSelectedComment"]),
    async confirmDeleteComment() {
      this.isDeleting = true;
      const commentId = this.comment.id;
      const { ok, errorMessage } = await deleteCommentByCommentId({ commentId });
      if (ok) {
        this.deleteCommentInCommentsData({ deletedCommentId: commentId });
      } else {
        console.error(errorMessage);
      }
      this.isDeleting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.CommentCardDelete {
  position: relative;
  text-align: left;
  color: #9397a6;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  &__title {
    color: #ffffff;
    font-weight: 500;
  }
  &__btns {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
