<template>
  <div ref="playerSetting" class="DPlayerSetting" tabindex="0" @blur="$emit('close')">
    <!-- Menu -->
    <template v-if="!currentSetting">
      <div class="DPlayerSetting__header">
        <label>{{ $t("player.settings").toUpperCase() }}</label>
        <icon-base
          class="DPlayerSetting__closeBtn"
          color="#9397A6"
          height="12"
          width="12"
          @click.native="$emit('close')"
        >
          <d-icon-close-2 />
        </icon-base>
      </div>
      <div class="DPlayerSetting__body">
        <!-- Auto play -->
        <d-player-setting-item
          class="DPlayerSetting__DPlayerSettingItem"
          :canClick="false"
          value="autoPlay"
          :label="$t(LABEL_MAP.autoPlay)"
        >
          <template slot="icon">
            <d-icon-auto-play />
          </template>
          <template slot="feature">
            <v-switch
              class="DPlayerSetting__autoPlaySwitch"
              :class="{ 'DPlayerSetting__autoPlaySwitch--off': !autoPlayModel }"
              v-model="autoPlayModel"
              color="#4689F3"
              :readonly="true"
              @click.stop="changeAutoPlay(!autoPlayModel)"
            />
          </template>
        </d-player-setting-item>
        <!-- Subtitles -->
        <d-player-setting-item
          class="DPlayerSetting__DPlayerSettingItem"
          :canClick="true"
          value="subtitles"
          :label="$t(LABEL_MAP.subtitles)"
          :currentStatesText="currentSubtitleText"
          @trigger-setting="currentSetting = $event"
        >
          <template slot="icon">
            <d-icon-subtitles />
          </template>
        </d-player-setting-item>
        <!-- Quality -->
        <d-player-setting-item
          v-if="steps[currentStep].videos && !getter_is_gen2"
          class="DPlayerSetting__DPlayerSettingItem"
          :canClick="true"
          value="quality"
          :label="$t(LABEL_MAP.quality)"
          :currentStatesText="currentQualityText"
          @trigger-setting="currentSetting = $event"
        >
          <template slot="icon">
            <d-icon-quality />
          </template>
        </d-player-setting-item>
        <!-- Speed -->
        <d-player-setting-item
          class="DPlayerSetting__DPlayerSettingItem"
          :canClick="true"
          value="speed"
          :label="$t(LABEL_MAP.speed)"
          :currentStatesText="currentPlaySpeedText"
          @trigger-setting="currentSetting = $event"
        >
          <template slot="icon">
            <d-icon-speed />
          </template>
        </d-player-setting-item>
      </div>
    </template>
    <!-- Detail Menu -->
    <template v-else>
      <div class="DPlayerSetting__header DPlayerSetting__header--detail">
        <icon-base
          class="DPlayerSetting__backBtn"
          color="#ffffff"
          height="14"
          width="14"
          @click.native="currentSetting = null"
        >
          <d-icon-arrow-back />
        </icon-base>
        <label class="DPlayerSetting__detailLabel">{{ $t(LABEL_MAP[currentSetting]) }}</label>
      </div>
      <div class="DPlayerSetting__body">
        <d-player-setting-subtitles
          v-if="currentSetting === 'subtitles'"
          :isUserSetSubtitleToNull="isUserSetSubtitleToNull"
          :steps="steps"
          :currentStep="currentStep"
          :subtitlesChoice="subtitlesChoice"
          @change-subtitles="$emit('change-subtitles', $event)"
        />
        <d-player-setting-quality
          v-else-if="currentSetting === 'quality'"
          :isUseHlsRezOptions="isUseHlsRezOptions"
          :videos="steps[currentStep].videos"
          :videoResolution="videoResolution"
          :hlsRezOptions="hlsRezOptions"
          :hlsCurrentLevel="hlsCurrentLevel"
          @set-quality-video-resolution="$emit('set-quality-video-resolution', $event)"
          @set-quality-hls-rez="
            qualityCurrentVal = $event.qualityCurrentVal;
            $emit('set-quality-hls-rez', $event);
          "
        />
        <d-player-setting-speed
          v-else-if="currentSetting === 'speed'"
          :speedOption="speedOption"
          :currentPlaySpeed="currentPlaySpeed"
          @change-speed="$emit('change-speed', $event)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconAutoPlay from "@/components/icons/player/DIconAutoPlay.vue";
import DIconSubtitles from "@/components/icons/player/DIconSubtitles.vue";
import DIconQuality from "@/components/icons/player/DIconQuality.vue";
import DIconSpeed from "@/components/icons/player/DIconSpeed.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import DPlayerSettingItem from "@/components/DWorkflowPlayer/setting/DPlayerSettingItem.vue";
import DPlayerSettingSubtitles from "@/components/DWorkflowPlayer/setting/DPlayerSettingSubtitles.vue";
import DPlayerSettingQuality from "@/components/DWorkflowPlayer/setting/DPlayerSettingQuality.vue";
import DPlayerSettingSpeed from "@/components/DWorkflowPlayer/setting/DPlayerSettingSpeed.vue";
import { getUniversalLanguageFullName } from "@/js/workflow/language.js";
import { mapGetters } from "vuex";
export default {
  name: "DPlayerSetting",
  components: {
    IconBase,
    DIconClose2,
    DIconAutoPlay,
    DIconSubtitles,
    DIconQuality,
    DIconSpeed,
    DIconArrowBack,
    DPlayerSettingItem,
    DPlayerSettingSubtitles,
    DPlayerSettingQuality,
    DPlayerSettingSpeed,
  },
  props: {
    showSettingPopup: {
      type: Boolean,
      default: false,
    },
    /** Auto play */
    autoNextStep: {
      type: Boolean,
      default: false,
    },
    /** Subtitle */
    isUserSetSubtitleToNull: {
      type: Boolean,
      default: false,
    },
    steps: {
      // {language}
      type: Array,
      default: () => [],
    },
    subtitlesChoice: {
      type: String,
    },
    /** subtitle */
    currentStep: {
      type: Number,
    },
    /** Quality */
    hasHls: {
      type: Boolean,
      default: false,
    },
    videoResolution: {
      type: String,
    },
    hlsRezOptions: {
      type: Array,
    },
    hlsRefs: {
      type: Array,
    },
    hlsCurrentLevel: {
      type: [Number, String],
    },
    /** Speed */
    speedOption: {
      type: Array,
    },
    currentPlaySpeed: {
      // origin: playspeed
      type: Number,
    },
  },
  data() {
    return {
      LABEL_MAP: {
        autoPlay: "player.autoPlay",
        subtitles: "player.subtitles",
        quality: "player.quality",
        speed: "player.speed",
      },
      currentSetting: null,
      autoPlayModel: false,
      qualityCurrentVal: 0,
      hlsRefsSyncList: [],
    };
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
    currentSubtitleText() {
      const languageFullName = getUniversalLanguageFullName(this.$dictionary);
      return this.subtitlesChoice ? languageFullName[this.subtitlesChoice] : this.$t("player.off");
    },
    isUseHlsRezOptions() {
      /** this.hlsRefs could not sync with computed */
      return this.hasHls && this.hlsRefsSyncList.length > 0;
    },
    currentQualityText() {
      const text = this.isUseHlsRezOptions ? this.qualityCurrentVal : this.videoResolution;
      return text === "Auto" ? text : `${text}p`;
    },
    currentPlaySpeedText() {
      return this.currentPlaySpeed === 1 ? this.$t("player.normal") : `${this.currentPlaySpeed}x`;
    },
  },
  watch: {
    showSettingPopup: {
      handler(newVal = true) {
        this.currentSetting = null;
        /** div to add blur event on setting, need to be focused first  */
        if (newVal && this.$refs.playerSetting) {
          this.$refs.playerSetting.focus();
        }
      },
    },
    isUseHlsRezOptions: {
      handler(newVal) {
        if (!newVal) return;
        this.qualityCurrentVal = this.hlsRezOptions[this.hlsCurrentLevel];
      },
      immediate: true,
    },
  },
  methods: {
    changeAutoPlay(autoPlayModel) {
      this.autoPlayModel = autoPlayModel;
      this.$emit("change-auto-play", { autoPlay: this.autoPlayModel });
    },
  },
  updated() {
    /** Because this.hlsRefs could not sycn value with computed, use updated event to trace value */
    if (this.hlsRefs && this.hlsRefs.length > 0 && this.hlsRefsSyncList.length === 0) {
      this.hlsRefsSyncList = this.hlsRefs;
    }
  },
  created() {
    this.autoPlayModel = this.autoNextStep;
  },
};
</script>

<style lang="scss" scoped>
$settingPCHeight: 296px;
$maxSettingHeight: 544px;
$settingYPadding: 24px;
$headerHeight: 40px;
.DPlayerSetting {
  width: 375px;
  min-width: 375px;
  min-height: $settingPCHeight;
  max-height: calc(100% - 200px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 0 0 $settingYPadding 0;
  background: #1d1e21;
  transition: height 0.5s;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: #2c2d32;
  }
  &:focus {
    outline: none;
  }
  @include mobile {
    height: 100%;
    min-height: 200px;
    max-height: 500px;
    max-width: 100%;
    @media only screen and (orientation: portrait) {
      height: auto;
      border-radius: 8px;
    }
  }
  &__header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    height: $headerHeight;
    padding: 42px 24px 24px 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #9397a6;
    background: #1d1e21;
    &--detail {
      justify-content: flex-start;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__closeBtn {
    cursor: pointer;
  }
  &__backBtn {
    cursor: pointer;
  }
  &__DPlayerSettingItem {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  /** auto play */
  &__autoPlaySwitch,
  &__autoPlaySwitch ::v-deep .v-input__slot {
    margin: 0;
  }
  &__autoPlaySwitch {
    margin-top: -10px;
    margin-right: -10px;
    height: 24px;
  }
  &__autoPlaySwitch--off ::v-deep .v-input--switch__track {
    background: #3d3f45;
  }
  /** Detail setting */
  &__detailLabel {
    margin-left: 16px;
    font-weight: 500;
    color: #ffffff;
  }
  &__body {
    position: relative;
    padding: 0px 14px;
  }
}
</style>
