<template>
  <div class="PlayerToolbox" :style="{ '--video-height': `${videoHeight}px` }">
    <div class="PlayerToolbox__tabs">
      <d-tabs v-model="currentTab" :tabNames="tabNames" autoWidth height="54" :autotest="tabAutotest">
        <template slot="tabName" slot-scope="props">
          <span class="PlayerToolbox__tabs--text">{{ props.item }}</span>
          <span v-if="tabs[props.index].tag" class="PlayerToolbox__tabs--tag">{{ tabs[props.index].tag }}</span>
        </template>
      </d-tabs>
    </div>
    <div
      ref="ToolboxContainer"
      class="PlayerToolbox__container"
      @scroll="handleScroll($event)"
      v-d-resize-observer="handleToolboxWidthSize"
    >
      <player-steps-main
        v-if="tabs[currentTab].key === 'steps'"
        ref="PlayerStepsMain"
        :workflowId="workflowId"
        :isSmallToolboxSize="isSmallToolboxSize"
      />
      <player-comments-main
        v-else-if="tabs[currentTab].key === 'comments'"
        ref="PlayerCommentsMain"
        :workflowId="workflowId"
        @scroll-to-top="scrollToTop()"
      />
      <player-sop-main v-else-if="tabs[currentTab].key === 'sop'" />
      <qa key="qa" v-else-if="tabs[currentTab].key === 'Q&A'" :workflowId="workflowId" />
      <player-summary
        v-else-if="tabs[currentTab].key === 'summary' && workflowData.aiSummary"
        :summary="workflowData.aiSummary.content"
        :workflowId="workflowId"
        :workflow-language="workflowData.languageCode"
      />
    </div>

    <player-attachments-sidebar
      v-if="getter_show_attachments_list && !getter_show_attachments_popup_fullscreen"
      :isSmallToolboxSize="isSmallToolboxSize"
    />
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import DResizeObserver from "@/directives/d-resize-observer";
import DTabs from "@/components/ui_components/DTabs.vue";
import PlayerCommentsMain from "@/components/DWorkflowPlayer/comments/PlayerCommentsMain.vue";
import PlayerSopMain from "@/components/DWorkflowPlayer/sop/PlayerSopMain.vue";
import PlayerStepsMain from "@/components/DWorkflowPlayer/steps/PlayerStepsMain.vue";
import PlayerAttachmentsSidebar from "@/components/DWorkflowPlayer/attachments/PlayerAttachmentsSidebar.vue";
import PlayerSummary from "@/components/DWorkflowPlayer/summary/PlayerSummary.vue";
import Qa from "@/components/Q&A/Q&A.vue";
import environment from "@/../env.js";
import { mapState, mapGetters, mapActions } from "vuex";
import { getIsMobileSize } from "@/constants/device-version";
import { isLanguageSupport } from "@/js/workflow/language";

export default {
  name: "PlayerToolbox",
  directives: { DResizeObserver },
  components: {
    DTabs,
    PlayerCommentsMain,
    PlayerStepsMain,
    PlayerSopMain,
    PlayerSummary,
    Qa,
    PlayerAttachmentsSidebar,
  },
  props: {
    workflowId: String,
    isPublicPlayerMain: Boolean,
    videoHeight: Number,
  },
  watch: {
    getter_show_attachments_list: {
      handler(getter_show_attachments_list) {
        if (this.currentTab === 1 && getter_show_attachments_list) {
          this.currentTab = 0;
        }
      },
    },
    currentTab(tabIdx) {
      this.setIsQaTabOpen({ isOpen: this.tabs[tabIdx].key === "Q&A" });
    },
    getter_is_window_mode(is_window_mode) {
      if (!is_window_mode && this.tabs[this.currentTab].key === "Q&A") {
        this.currentTab = 0;
      }
    },
  },
  data() {
    return {
      currentTab: 0,
      isMobileSize: getIsMobileSize(),
      isSmallToolboxSize: false,
    };
  },
  methods: {
    ...mapActions("workflowPlayer", ["setIsQaTabOpen"]),
    handleScroll(event) {
      if (!this.$refs.PlayerCommentsMain) return;
      // scroll to bottom to load more comments
      const scrollTop = event.target.scrollTop;
      const areaToScroll = event.target.scrollHeight - event.target.offsetHeight;
      const isReadyToGetMoreWorkflow = areaToScroll <= scrollTop;
      if (isReadyToGetMoreWorkflow) {
        this.$refs.PlayerCommentsMain.loadMoreComments();
      }
    },
    handleToolboxWidthSize: debounce(function ({ clientWidth }) {
      const MAX_WIDTH = 300;
      this.isSmallToolboxSize = clientWidth < MAX_WIDTH;
      this.isMobileSize = getIsMobileSize();
    }, 500),
    scrollToTop() {
      const container = this.$refs.ToolboxContainer;
      container.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    ...mapGetters("permission", [
      "getter_workflow_comments_enabled",
      "getter_ai_qa_feature_enabled",
      "getter_ai_qa_enabled",
    ]),
    ...mapGetters("workflowPlayer", [
      "getter_show_attachments_popup_fullscreen",
      "getter_show_attachments_list",
      "getter_sop_content",
      "getter_is_window_mode",
    ]),
    ...mapState("workflowPlayer", ["workflowData", "isDivideMode"]),
    tabs() {
      let tabs = [{ name: this.$t("all.steps"), key: "steps", autotest: "steps-tab" }];
      const isCanShowSummary = this.isDivideMode || this.isMobileSize;
      if (isCanShowSummary && this.workflowData.aiSummary) {
        tabs.push({ name: this.$t("maven.summary"), key: "summary", autotest: "summary-tab" });
      }
      const isSupport = isLanguageSupport(this.$settings?.ai.qa, this.workflowData.languageCode);
      if (isSupport && this.getter_ai_qa_enabled && this.getter_ai_qa_feature_enabled) {
        tabs.push({ name: this.$t("askMaven.qa"), key: "Q&A", autotest: "ai-qa-tab", tag: "BETA" });
      }
      if (this.getter_sop_content.length > 0) {
        tabs.push({ name: this.$t("mavenSop.aiSop"), key: "sop", autotest: "sop-tab", tag: "BETA" });
      }
      if (this.showComments) {
        tabs.push({ name: this.$t("player.comments"), key: "comments", autotest: "comments-tab" });
      }
      return tabs;
    },
    tabAutotest() {
      return this.tabs.map((item) => item.autotest);
    },
    tabNames() {
      return this.tabs.map((item) => item.name);
    },
    showComments() {
      // comments part is not enabled for China version and public link, other versions are based on the settings in admin
      return environment.backendType !== "ali" && !this.isPublicPlayerMain && this.getter_workflow_comments_enabled;
    },
  },
};
</script>

<style lang="scss" scoped>
$tabsHeight: 64px;
$headerHeight: 70px;

.PlayerToolbox {
  display: flex;
  flex-direction: column;

  position: relative;
  height: 100%;
  width: 100%;
  background: var(--dGrey1-color);
  margin-bottom: var(--spacing-07);
  &__tabs {
    flex: 0 0 auto;

    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--dGrey1-color);

    display: flex;
    margin-bottom: 16px;
    overflow-x: auto;

    @include mobile {
      @media screen and (orientation: portrait) {
        top: var(--video-height);
      }
    }

    & ::v-deep .blue-bar {
      border-bottom: 3px solid #4689f3;
    }
    &--text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
    }
    &--tag {
      top: 0;
      right: 0;
      background: #4689f3;
      color: white;
      font-size: 12px;
      padding: 4px;
      border-radius: 8px;
    }
  }
  &__container {
    flex: 1;
    height: 100%;
    overflow: overlay;
    position: relative;
    width: 100%;

    @include scrollbar();
  }
}

// mobile
@media screen and (max-width: 599px) {
  .PlayerToolbox {
    background: var(--dGrey1-color);
  }
}
</style>
