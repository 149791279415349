<template>
  <div class="DCarousel">
    <div class="DCarousel__top">
      <div v-if="showArrows" class="DCarousel__btnDiv">
        <button class="DCarousel__btnDiv--btn" @click="prevImage">
          <icon-base color="#FFFFFF" height="22" width="22">
            <d-icon-arrow-back />
          </icon-base>
        </button>
      </div>

      <div class="DCarousel__imgDiv" :class="{ 'DCarousel__imgDiv--hideArrow': !showArrows }">
        <div class="DCarousel__imgDivAction" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
          <div class="DCarousel__imgDivAction--clickableArea" @click="prevImage"></div>
          <div class="DCarousel__imgDivAction--clickableArea" @click="nextImage"></div>
        </div>
        <d-image :aspect-ratio="16 / 9" :src="imgURL" contain class="DCarousel__imgDiv--img"></d-image>
      </div>

      <div v-if="showArrows" class="DCarousel__btnDiv">
        <button class="DCarousel__btnDiv--btn" @click="nextImage">
          <icon-base color="#FFFFFF" height="22" width="22" class="DCarousel__btnDiv--btnRight">
            <d-icon-arrow-back />
          </icon-base>
        </button>
      </div>
    </div>

    <div class="DCarousel__bottom">
      <div v-if="pagingDots" class="DCarousel__bottom--dots">
        <div
          v-for="(img, index) in items"
          :key="index"
          class="DCarousel__bottom--dot"
          :class="{
            'DCarousel__bottom--currentDot': index === currentIndex,
            'DCarousel__bottom--smallDot':
              (index === dotInfo.firstIndex && dotInfo.firstIndex !== 0) ||
              (index === dotInfo.lastIndex && index !== items.length - 1),
            'DCarousel__bottom--hiddenDot': index < dotInfo.firstIndex || index > dotInfo.lastIndex,
          }"
          @click="handleDotClick(index)"
        ></div>
      </div>

      <div v-if="pagingNumber" class="mt-4">
        <d-carousel-paging-buttons
          :currentIndex="currentIndex"
          :total="items.length"
          @handlePrevClick="prevImage"
          @handleNextClick="nextImage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import DImage from "@/components/ui_components/DImage.vue";
import DCarouselPagingButtons from "@/components/ui_components/DCarouselPagingButtons.vue";

export default {
  name: "DCarousel",
  props: {
    value: Number, // init and sync current image index in parent component (optional)
    items: Array, // all images array
    showArrows: Boolean, // show arrow buttons side by side for prev / next image
    pagingDots: Boolean, // use dots for paging on bottom
    pagingNumber: Boolean, // use number and arrow buttons for paging on bottom
  },
  components: {
    IconBase,
    DIconArrowBack,
    DImage,
    DCarouselPagingButtons,
  },
  created() {
    if (this.value) {
      this.currentIndex = this.value;
    }
  },
  data() {
    return {
      currentIndex: 0,
      touchStartX: 0,
      touchEndX: 0,
    };
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },
    handleTouchEnd(event) {
      event.preventDefault();
      event.stopPropagation();
      this.touchEndX = event.changedTouches[0].screenX;
      this.handleSwipe();
    },
    handleSwipe() {
      if (this.touchEndX < this.touchStartX) {
        // Swipe left
        this.nextImage();
      } else {
        // Swipe right
        this.prevImage();
      }
    },
    handleDotClick(index) {
      this.currentIndex = index;
      this.$emit("input", this.currentIndex);
    },
    prevImage() {
      if (this.currentIndex === 0) {
        const lastIdx = this.items.length - 1;
        this.currentIndex = lastIdx;
      } else {
        this.currentIndex--;
      }
      this.$emit("input", this.currentIndex);
    },
    nextImage() {
      const lastIdx = this.items.length - 1;
      if (this.currentIndex === lastIdx) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
      this.$emit("input", this.currentIndex);
    },
    getFirstIndex(currentIndex, maxDots) {
      const endIdx = this.items.length - 1;
      const half = Math.floor(maxDots / 2);
      // show dots by the front part
      if (currentIndex - half < 0) {
        return 0;
      }
      // show dots by the end part
      if (currentIndex - half > endIdx - maxDots + 1) {
        return endIdx - maxDots + 1;
      }
      // currentIndex is in the middle
      return currentIndex - half;
    },
  },
  computed: {
    dotInfo() {
      const MAX_DOTS = 7;
      const total = this.items.length;
      const showExactDots = total <= MAX_DOTS;
      if (showExactDots) {
        return {
          firstIndex: 0,
          lastIndex: total,
        };
      }
      const firstIndex = this.getFirstIndex(this.currentIndex, MAX_DOTS);
      return {
        firstIndex,
        lastIndex: firstIndex + MAX_DOTS - 1,
      };
    },
    imgURL() {
      const url = this.items[this.currentIndex];
      return url ? url : "";
    },
  },
};
</script>

<style lang="scss" scoped>
$bottomHeight: 48px;
$dotSize: 8px;
.DCarousel {
  position: relative;
  height: 100%;
  width: 100%;
  &__top {
    position: relative;
    height: calc(100% - #{$bottomHeight});
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__btnDiv {
    width: 48px;
    height: 100%;
    display: flex;
    align-items: center;
    &--btn {
      width: 48px;
      height: 48px;
      padding: 8px 0 0 0;
      border-radius: 24px;
      background: #28292e;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    &--btnRight {
      transform: scaleX(-1);
      filter: FlipH;
    }
  }
  &__imgDiv {
    position: relative;
    height: 100%;
    width: calc(100% - 176px);
    border-radius: 8px;
    background-color: #1d1e21;
    margin: 0 auto;
    overflow: hidden;
    &--hideArrow {
      width: 100%;
    }
    &--img {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }
  &__imgDivAction {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    &--clickableArea {
      position: relative;
      width: 50%;
      height: 100%;
      cursor: pointer;
    }
  }
  &__bottom {
    position: relative;
    height: $bottomHeight;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &--dots {
      height: $dotSize;
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--dot {
      position: relative;
      height: $dotSize;
      width: $dotSize;
      margin: 0 4px;
      border-radius: 50%;
      background: #4d4d4d;
      cursor: pointer;
      transition: 0.3s;
    }
    &--currentDot {
      background: #ededed;
      transition: 0.3s;
    }
    &--smallDot {
      height: 4px;
      width: 4px;
      transition: 0.3s;
    }
    &--hiddenDot {
      height: 0px;
      width: 0px;
      margin: 0;
      transition: 0.3s;
      pointer-events: none;
    }
  }
}
</style>
