<template>
  <div class="PlayerMain">
    <player-loading v-if="isLoading" />

    <!-- Unpublished video -->
    <template v-else>
      <player-unpublished-video
        v-if="showErrorMsg"
        :isPublic="isPublic"
        :isUsingIframe="isUsingIframe"
        :isEmbedDeephow="isEmbedDeephow"
        :isOpenedAsPopup="isNewPlayer || isSkillPlayer || isSearchPlayer"
        @close-player="closePlayer"
      >
        <span v-if="isDeleted" v-html="$t('player.thisWorkflowWasDeleted')"></span>
        <span v-else-if="isNotExists" v-html="$t('player.thisWorkflowDoesNotExist')"></span>
      </player-unpublished-video>

      <player-processed-video
        v-else
        ref="PlayerProcessedVideo"
        :isPublic="isPublic"
        :workflow="workflowData"
        :steps="getters_steps"
        :attachmentsData="getter_attachments"
        :stepId="stepId"
        :startTimestamp="startTimestamp"
        :isSkillPlayer="isSkillPlayer"
        :showSkillNext="showSkillNext"
        :isSearchPlayer="isSearchPlayer"
        :isNewPlayer="isNewPlayer"
        :hideCloseBtn="hideCloseBtn"
        :isUsingIframe="isUsingIframe"
        :isEmbedDeephow="isEmbedDeephow"
        :isPlayerAttachmentShowUp.sync="isHidePlayerAnnotation"
        :showTopBarForcible="showTopBarForcible"
        @closePlayerEvent="closePlayer"
        @workflow-ended="handleWorkflowEnded"
        @fetchVideoDataAndCurrentStepIndex="fetchVideoDataAndCurrentStepIndex"
      >
        <template slot="annotation">
          <PlayerAnnotation
            :isHideCanvas.sync="isHidePlayerAnnotation"
            :workflow="workflowData"
            :videoData="videoData"
          />
        </template>
        <template slot="append-video">
          <player-quiz-mask v-if="isShowPlayerQuizMask" @close-mask="isShowPlayerQuizMask = false" />
        </template>
      </player-processed-video>
    </template>
  </div>
</template>

<script>
import PlayerLoading from "@/components/DWorkflowPlayer/player/message/PlayerLoading.vue";
import PlayerProcessedVideo from "@/components/DWorkflowPlayer/player/PlayerProcessedVideo.vue";
import PlayerUnpublishedVideo from "@/components/DPlayer/PlayerUnpublishedVideo.vue";
import Analytics from "@/js/analytics/analytics";
import MixinSkills from "@/components/MixinSkills";
import PlayerAnnotation from "@/components/DPlayer/PlayerAnnotation";
import { checkCodeIsNotAllowIp } from "@/js/login/checkRestrictedIp";
import { authWorkflowPermission } from "@/server/api-server";
import { loggingError } from "@/server/error-log-server.js";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "PlayerMain",
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
    isSkillPlayer: {
      type: Boolean,
      default: false,
    },
    skillWorkflowId: {
      type: String,
      default: "",
    },
    showSkillNext: {
      type: Boolean,
      default: false,
    },
    isSearchPlayer: {
      type: Boolean,
      default: false,
    },
    isNewPlayer: {
      type: Boolean,
      default: false,
    },
    searchWorkflowId: {
      type: String,
      default: "",
    },
    searchStepId: {
      type: String,
      default: "",
    },
    startTimestamp: {
      type: Number,
      default: 0,
    }, //route param that allows user to jump to a specific timestamp in a step
    hideCloseBtn: {
      type: Boolean,
      default: false,
    },
    showTopBarForcible: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [MixinSkills],
  components: {
    PlayerQuizMask: () => import("@/components/DWorkflowPlayer/player/message/PlayerQuizMask.vue"),
    PlayerLoading,
    PlayerProcessedVideo,
    PlayerUnpublishedVideo,
    PlayerAnnotation,
  },
  data() {
    const videoData = {
      clientWidth: 0,
      clientHeight: 0,
      currentStepIndex: 0,
      currentVideoTime: 0,
    };
    return {
      videoData,
      workflowId: "",
      stepId: "", //route param that allow user to jump to the specific step
      isShowPlayerQuizMask: false,
      isUsingIframe: false,
      isEmbedDeephow: false,
      isDeleted: false,
      isNotExists: false,
      isHistoryStateChanged: false,
      countApi: 0,
      isHidePlayerAnnotation: false,
      isLoadingWorkflowData: true,
      isLoadingOrganization: true,
    };
  },

  async created() {
    if (this.isSkillPlayer) {
      this.workflowId = this.skillWorkflowId;
    } else if (this.isSearchPlayer || this.isNewPlayer) {
      this.workflowId = this.searchWorkflowId;
      this.stepId = this.searchStepId || this.$route.params.stepId;
    } else {
      this.workflowId = this.$route.params.workflowId;
      this.stepId = this.$route.params.stepId;
    }

    if (!this.isPublic) {
      if (!this.isLogin) {
        this.$router.push({
          name: "Auth",
          query: { redirect: "/playermain/" + this.workflowId },
        });
        return;
      }

      const { ok, data, code } = await authWorkflowPermission(this.workflowId);
      if (!ok || !data.item.allowView) {
        if (!checkCodeIsNotAllowIp(code)) {
          this.$router.push({ name: "403" });
        }
        return;
      }

      this.checkUrlMatchPlayerMain();
    }

    const { isSuccess } = await this.fetchWorkflowData({ workflowId: this.workflowId, isPublic: this.isPublic });
    this.isLoadingWorkflowData = false;
    if (isSuccess) {
      const { isPreviewVideoMode } = this.$route.params;
      if (!this.workflowData.published && !isPreviewVideoMode) {
        // unpublished workflow can only play by Preview Video Mode
        this.showErrorMessage();
        this.clearWorkflowData();
      }
    } else {
      this.showErrorMessage();
    }

    // check if it's using iframe
    if (window.self !== window.top) {
      if (this.$route.name == "PlayerEmbed") {
        // "PlayerEmbed" is for the iFrame iFrame on DPopupPlayer, only use inside DeepHow
        // UI is the same as Theater Mode
        this.isUsingIframe = false; // don't use iFrame controls
        this.isEmbedDeephow = true;
        this.enterTheaterMode();
      } else {
        // for inseted iFrame player on other websites, like ABI is using on Acadia
        this.isUsingIframe = true;
      }
    }

    if (!this.isPublic) {
      this.getTrackingDocumentAndSetAnalyticsTrack();
    }
  },
  beforeDestroy() {
    Analytics.removeEventProperties("Workflow ID");
    Analytics.removeEventProperties("Workspace ID");
    if (this.isHistoryStateChanged) history.back();
    this.clearAllWorkflowPlayerData();
  },
  methods: {
    ...mapActions("workflowPlayer", [
      "fetchWorkflowData",
      "clearWorkflowData",
      "showErrorMessage",
      "clearAllWorkflowPlayerData",
      "enterTheaterMode",
    ]),
    closeVideoPlayer() {
      if (this.$refs.PlayerProcessedVideo) {
        this.$refs.PlayerProcessedVideo.closeVideoPlayer();
      }
    },
    async getTrackingDocumentAndSetAnalyticsTrack() {
      try {
        const data = await this.getUserWorkflowTracking(this.userProfile, this.workflowId);
        Analytics.setTrack({
          category: "PlayerMain",
          action: "Watch Workflow",
          name: "Watch Workflow",
          params: {
            dimension3: this.workflowId,
            dimension4: data[0].id,
            dimension5: this.workflowData.group,
          },
        });
      } catch (e) {
        if (e.code === "permission-denied" && this.countApi < 3) {
          setTimeout(() => {
            this.getTrackingDocumentAndSetAnalyticsTrack();
          }, 3000);
        }
        loggingError(e);
      } finally {
        this.countApi++;
      }
    },
    closePlayer: function () {
      if (this.isNewPlayer || this.isSkillPlayer) {
        this.$emit("close-search-player");
      } else {
        this.$router.push("/navigator/home");
      }
    },
    checkUrlMatchPlayerMain() {
      if (!location.pathname.match(/\/playermain/)) {
        this.isHistoryStateChanged = true;
        history.pushState({}, null, "/playermain/" + this.workflowId);
      }
    },
    fetchVideoDataAndCurrentStepIndex(videoData) {
      for (const key in videoData) {
        if (Object.prototype.hasOwnProperty.call(videoData, key)) {
          this.videoData[key] = videoData[key];
        }
      }
    },
    handleWorkflowEnded() {
      if (this.$route.params.isPreviewVideoMode) return;
      if (this.getter_is_wait_to_quiz && this.getter_quiz_enabled) {
        this.isShowPlayerQuizMask = true;
      } else if (this.isSkillPlayer) {
        this.$emit("workflow-ended");
      }
    },
  },
  computed: {
    ...mapState("auth", ["isLogin", "userProfile"]),
    ...mapState("workflowPlayer", ["workflowData", "showErrorMsg"]),
    ...mapGetters("workflowPlayer", ["getters_steps", "getter_attachments", "getter_is_wait_to_quiz"]),
    ...mapGetters(["getter_organization"]),
    ...mapGetters("permission", ["getter_quiz_enabled"]),
    isLoading() {
      if (this.isLoadingWorkflowData) return true;
      return this.isPublic ? false : this.isLoadingOrganization;
    },
  },
  watch: {
    getter_organization: {
      handler(getter_organization) {
        if (getter_organization) this.isLoadingOrganization = false;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.PlayerMain {
  position: fixed;
  inset: 0;
  background: #242527;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
