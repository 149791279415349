<template>
  <div class="PlayerFullscreenPublic">
    <player-main isPublic />
  </div>
</template>

<script>
export default {
  name: "PlayerFullscreenPublic",
  components: {
    PlayerMain: () => import("@/components/DWorkflowPlayer/player/PlayerMain.vue"),
  },
};
</script>

<style lang="scss" scoped>
.PlayerFullscreenPublic {
  position: fixed;
  inset: 0;
  background: #242527;
}
</style>
