<template>
  <div class="CommentCardDisplay">
    <div class="CommentCardDisplay__top text-no-wrap">
      <router-link :to="{ name: 'UserProfileMain', params: { uid: comment.uid } }" target="_blank">
        <div>
          <d-avatar :size="32" :userId="comment.uid" />
        </div>
      </router-link>
      <span class="CommentCardDisplay__top--name text-truncate">{{ comment.displayName }}</span>
      <span class="CommentCardDisplay__top--dot"></span>
      <span class="CommentCardDisplay__top--time">{{ timeSince }}</span>
      <span v-if="comment.updatedAt" class="CommentCardDisplay__top--time">&nbsp;({{ $t("player.edited") }})</span>
      <v-spacer></v-spacer>
      <template v-if="selectedComment.updated === comment.id">
        <span class="CommentCardDisplay__top--success">
          <icon-base height="12" width="12" class="mr-1">
            <d-icon-done />
          </icon-base>
          {{ $t("player.changesSaved") }}
        </span>
      </template>
      <div v-else-if="permissions.canDelete || permissions.canEdit" class="CommentCardDisplay__top--more">
        <button
          class="CommentCardDisplay__top--moreBtn"
          :class="{
            'CommentCardDisplay__top--moreBtnActive': showMenu,
            'CommentCardDisplay__top--moreBtnMobile': isMobileDevice,
          }"
          @click.stop="showMenu = !showMenu"
        >
          <icon-base width="16" height="16">
            <d-icon-more />
          </icon-base>
        </button>
        <div v-if="showMenu" class="CommentCardDisplay__menu" @click.stop>
          <div
            v-if="permissions.canEdit"
            class="CommentCardDisplay__menu--item"
            @click="storeEditComment({ commentId: comment.id })"
          >
            <icon-base width="16" height="16" class="CommentCardDisplay__menu--icon">
              <d-icon-pen-edit />
            </icon-base>
            {{ $t("player.edit") }}
          </div>
          <div
            v-if="permissions.canDelete"
            class="CommentCardDisplay__menu--item"
            @click="storeDeleteComment({ commentId: comment.id })"
          >
            <icon-base width="16" height="16" class="CommentCardDisplay__menu--icon">
              <d-icon-trash-can />
            </icon-base>
            {{ $t("all.delete") }}
          </div>
        </div>
        <div v-if="showMenu" class="CommentCardDisplay__menuClose" @click="showMenu = false"></div>
      </div>
    </div>
    <div class="CommentCardDisplay__comment" v-text="comment.comment"></div>
    <div class="CommentCardDisplay__reactions">
      <comment-reactions :comment="comment" :isMobileDevice="isMobileDevice" />
    </div>
  </div>
</template>

<script>
import DAvatar from "@/components/ui_components/DAvatar.vue";
import IconBase from "@/components/IconBase";
import DIconMore from "@/components/icons/DIconMore.vue";
import DIconPenEdit from "@/components/icons/player/DIconPenEdit.vue";
import DIconTrashCan from "@/components/icons/player/DIconTrashCan.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
import CommentReactions from "@/components/DWorkflowPlayer/comments/CommentReactions.vue";
import { formatIsoStrTimeSince } from "@/js/time/timeSince.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "CommentCardDisplay",
  components: { DAvatar, IconBase, DIconMore, DIconPenEdit, DIconTrashCan, DIconDone, CommentReactions },
  props: {
    comment: {
      type: Object,
      require: true,
    },
    isMobileDevice: Boolean,
  },
  data() {
    return {
      showMenu: false,
      timeSince: "",
      permissions: {
        canDelete: false,
        canEdit: false,
      },
    };
  },
  created() {
    this.setPermissions();
    this.timeSince = formatIsoStrTimeSince({
      isoStr: this.comment.createdAt,
      vueInstanceRef: this,
      simplify: true,
    });
  },
  methods: {
    ...mapActions("workflowComments", ["storeEditComment", "storeDeleteComment"]),
    setPermissions() {
      const isAdmin = this.$userProfile.role === "admin-organization" || this.$userProfile.role === "admin-group";
      const isCurrentUser = this.$userProfile.id === this.comment.uid;
      this.permissions.canDelete = isAdmin || isCurrentUser;
      this.permissions.canEdit = isCurrentUser;
    },
  },
  computed: {
    ...mapState("workflowComments", ["selectedComment"]),
  },
};
</script>

<style lang="scss" scoped>
.CommentCardDisplay {
  position: relative;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  &__top {
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &--name {
      max-width: 45%;
      margin-left: 8px;
      text-overflow: ellipsis;
    }
    &--dot {
      height: 3px;
      width: 3px;
      background-color: #9397a6;
      border-radius: 50%;
      margin-left: 8px;
      margin-right: 8px;
    }
    &--time {
      color: #9397a6;
      font-size: 12px;
    }
    &--success {
      margin-left: 8px;
      font-size: 12px;
      line-height: 14px;
      color: #3abf6f;
    }
    &--more {
      position: relative;
    }
    &--moreBtn {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      padding: 8px;
      color: #9397a6;
      opacity: 0;
      transform: rotate(90deg);
      &:hover {
        background-color: #333437;
      }
    }
    &--moreBtnActive {
      opacity: 1;
      background-color: #333437;
    }
    &--moreBtnMobile {
      opacity: 1;
      color: #ffffff;
    }
  }
  &__menu {
    z-index: 2;
    position: absolute;
    right: 0;
    min-width: 134px;
    padding: 8px;
    margin-top: 4px;
    background: #28292e;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.25);
    &--item {
      padding: 6px 8px;
      border-radius: 4px;
      text-transform: capitalize;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        background-color: #33353a;
      }
    }
    &--icon {
      margin-bottom: -2px;
      margin-right: 8px;
    }
  }
  &__menuClose {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &__comment {
    word-break: normal;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  &__reactions {
    margin-top: 12px;
  }
  &:hover .CommentCardDisplay__top--moreBtn {
    opacity: 1;
  }
}
</style>
