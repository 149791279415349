<template>
  <div class="LoadingMessage" :class="{ LoadingMessage__transition: transition, LoadingMessage__show: show }">
    <v-progress-circular
      size="16"
      width="1"
      color="#4689F3"
      indeterminate
      class="LoadingMessage__icon"
    ></v-progress-circular>
    <span class="LoadingMessage__text">{{ $t("player.loading") }}</span>
  </div>
</template>

<script>
export default {
  name: "LoadingMessage",
  props: {
    transition: Boolean,
    show: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.LoadingMessage {
  height: 24px;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
  &__icon {
    bottom: 2px;
    margin-right: 6px;
  }
  &__text {
    font-size: 14px;
    line-height: 24px;
    color: #4689f3;
  }
  &__transition {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: 0.4s;
  }
  &__show {
    height: 24px;
    opacity: 1;
    transition: 0.4s;
  }
}
</style>
