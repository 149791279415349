<template>
  <component
    class="PlayerAttachmentsSidebar"
    :is="isMobileStyle ? 'DTeleport' : 'div'"
    to=".App"
    :class="{ 'PlayerAttachmentsSidebar--mobile': isMobileStyle }"
    :style="mobileStyle"
  >
    <div class="PlayerAttachmentsSidebar__top">
      <section v-if="isMobileStyle">
        <div class="PlayerAttachmentsSidebar__top--title text-no-wrap text-truncate">
          <button @click="closeAttachments" class="PlayerAttachmentsSidebar__top--closeBtnMobile">
            <icon-base height="16" width="16" color="#ffffff">
              <d-icon-arrow-back />
            </icon-base>
          </button>
          <span class="ml-4">{{ $t("all.step") }} {{ stepNum }}.</span>
          {{ getter_selected_attachment_step.title }}
          <div class="PlayerAttachmentsSidebar__top--mobileLine"></div>
        </div>
      </section>
      <section v-else>
        <div class="PlayerAttachmentsSidebar__top--title text-no-wrap text-truncate">
          <span>{{ $t("all.step") }} {{ stepNum }}.</span>
          {{ getter_selected_attachment_step.title }}
        </div>
        <button class="PlayerAttachmentsSidebar__top--closeBtn" @click="closeAttachments">
          <v-icon color="white" small>close</v-icon>
        </button>
      </section>
      <div class="PlayerAttachmentsSidebar__top--btns">
        <attachment-btn-group
          :value="showPart"
          @input="updateShowPart($event)"
          :isSmallToolboxSize="isSmallToolboxSize"
        />
      </div>
    </div>
    <div class="PlayerAttachmentsSidebar__list">
      <div v-for="attachments in getter_current_step_attachments" :key="attachments.type">
        <div v-if="showPart === 'diagram'" class="PlayerAttachmentsSidebar__listDiagram">
          <div
            v-for="(item, idx) in attachments.diagrams"
            :key="attachments.type + idx"
            class="PlayerAttachmentsSidebar__listDiagram--card"
            @click.stop="(showPopup = true), (selectedDiagramIdx = idx)"
          >
            <attachment-diagram-card :imgURL="item" />
          </div>
        </div>
        <div v-else-if="showPart === 'links'">
          <div v-for="(item, idx) in attachments.links" :key="attachments.type + idx">
            <attachment-link-card :title="item.label" :link="item.link" />
          </div>
        </div>
        <div v-else-if="showPart === 'pdf'">
          <div v-for="(item, idx) in attachments.pdfs" :key="attachments.type + idx">
            <attachment-pdf-card :item="item" />
          </div>
        </div>
      </div>
    </div>

    <attachment-diagrams-popup
      v-if="showPopup"
      :isSmallToolboxSize="isSmallToolboxSize"
      :selectedDiagramIdx="selectedDiagramIdx"
      :stepNum="stepNum"
      :title="getter_selected_attachment_step.title"
      @close-popup="(showPopup = false), (selectedDiagramIdx = -1)"
    />
  </component>
</template>

<script>
import DImage from "@/components/ui_components/DImage.vue";
import IconBase from "@/components/IconBase";
import DTeleport from "@/components/ui_components/DTeleport.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import AttachmentBtnGroup from "@/components/DWorkflowPlayer/attachments/AttachmentBtnGroup.vue";
import AttachmentDiagramCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentDiagramCard.vue";
import AttachmentLinkCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentLinkCard.vue";
import AttachmentPdfCard from "@/components/DWorkflowPlayer/attachments/cards/AttachmentPdfCard.vue";
import AttachmentDiagramsPopup from "@/components/DWorkflowPlayer/attachments/AttachmentDiagramsPopup.vue";
import { getNumberWithZero } from "@/js/common/formatText.js";
import { mapActions, mapGetters } from "vuex";
import { IS_MOBILE, IS_USE_IFRAME } from "@/constants/device-version";

export default {
  name: "PlayerAttachmentsSidebar",
  components: {
    DTeleport,
    DImage,
    IconBase,
    DIconArrowBack,
    AttachmentBtnGroup,
    AttachmentDiagramCard,
    AttachmentLinkCard,
    AttachmentPdfCard,
    AttachmentDiagramsPopup,
  },
  props: {
    isSmallToolboxSize: Boolean,
  },
  watch: {
    showPopup: {
      handler(showPopup) {
        if (this.isMobileStyle) return;
        if (showPopup) {
          this.enterPictureInPictureMode();
          this.setIsStartTyping(); // disable keyup listener in player
          return;
        }
        this.exitPictureInPictureMode();
        this.setIsEndTyping();
      },
    },
    getter_current_step_attachments_diagrams() {
      this.showPopup = false;
    },
    getter_current_step_attachments: {
      handler(attachments) {
        if (attachments.length === 0) {
          this.closeAttachments();
        }
      },
    },
  },
  data() {
    return {
      isMobileStyle: IS_MOBILE && !IS_USE_IFRAME,
      showPopup: false,
      selectedDiagramIdx: -1,
      showPart: "diagram",
    };
  },
  methods: {
    ...mapActions("workflowPlayer", [
      "setSelectedAttachmentStep",
      "enterPictureInPictureMode",
      "exitPictureInPictureMode",
      "setIsStartTyping",
      "setIsEndTyping",
    ]),
    closeAttachments() {
      this.setSelectedAttachmentStep({ step: {} });
    },
    updateShowPart(event) {
      this.showPart = event;
    },
  },
  computed: {
    ...mapGetters("workflowPlayer", [
      "getter_selected_attachment_step",
      "getter_current_step_attachments",
      "getter_current_step_attachments_diagrams",
    ]),
    stepNum() {
      return getNumberWithZero({ num: this.getter_selected_attachment_step.stepIndex + 1 });
    },
    mobileStyle() {
      if (!this.isMobileStyle) return null;
      const pageWidth = window.innerWidth;
      const pageHeight = window.innerHeight;
      const paddingTop = 28;
      const height = pageHeight - (pageWidth / 16) * 9 - paddingTop;
      return {
        height: height + "px",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$topHeight: 120px;
.PlayerAttachmentsSidebar {
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #1d1e21;
  text-align: left;
  &--mobile {
    z-index: 999;
    position: fixed;
    width: 100%;
    top: unset;
    bottom: 0;
    left: 0;
    background: #1d1e21;
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &__top {
    height: $topHeight;
    width: 100%;
    padding: 24px 24px 0 24px;
    &--title {
      width: calc(100% - 30px);
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      span {
        font-weight: 400;
        color: #9397a6;
      }
    }
    &--closeBtnMobile {
      position: absolute;
      left: 14px;
      top: 20px;
      padding: 4px;
    }
    &--mobileLine {
      position: absolute;
      left: 12px;
      top: 58px;
      width: calc(100% - 24px);
      border-bottom: 1px solid #33353a;
    }
    &--closeBtn {
      position: absolute;
      right: 14px;
      top: 17px;
      padding: 4px;
    }
    &--btns {
      margin-top: 34px;
      min-height: 24px;
      width: 100%;
      display: flex;
    }
  }
  &__list {
    width: 100%;
    height: calc(100% - #{$topHeight});
    overflow: auto;
    padding: 0 24px 24px 24px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: #585858;
    }
  }
  &__listDiagram {
    position: relative;
    left: -10px;
    width: calc(100% + 20px);
    display: flex;
    flex-wrap: wrap;
    &--card {
      width: 50%;
      padding: 0 10px 24px 10px;
    }
  }
}
</style>
