<template>
  <div class="ReactionsInPlayer">
    <d-react-btn ref="DReactBtn" :workflowId="workflowId" />
    <div
      class="ReactionsInPlayer__num"
      :class="{ 'ReactionsInPlayer__num--desktop': !getter_is_window_mode }"
      @click="showReactPopup = true"
    >
      {{ reactionsNumber }}
    </div>
    <d-all-react-popup v-if="showReactPopup" @close-popup="showReactPopup = false" />
  </div>
</template>

<script>
import DReactBtn from "@/components/DWorkflowPlayer/info/btns/DReactBtn";
import DAllReactPopup from "@/components/DWorkflowPlayer/info/DAllReactPopup";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "ReactionsInPlayer",
  components: {
    DReactBtn,
    DAllReactPopup,
  },
  props: {
    workflowId: String,
  },
  async created() {
    this.storeReactionSet();
    // get reactions data if it's on iFrame
    if (this.workflowId !== this.reactionWorkflowId) {
      await this.fetchReactionsByWorkflowId({ workflowId: this.workflowId });
    }
  },
  data() {
    return {
      showReactPopup: false,
    };
  },
  methods: {
    ...mapActions("workflowReactions", ["storeReactionSet", "fetchReactionsByWorkflowId"]),
  },
  computed: {
    ...mapState("workflowReactions", ["reactionWorkflowId", "reactionsByWorkflow"]),
    ...mapGetters("workflowPlayer", ["getter_is_window_mode"]),
    reactionsNumber() {
      const num = this.reactionsByWorkflow.length || 0;
      if (num > 9) {
        return num;
      } else {
        return `0${num}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$smallPlayerMaxWidth: 1263px;
.ReactionsInPlayer {
  // over height than `.time-detect-div(z-index=5)`
  // if use DTeleport to show reaction tooltip, when user hover to tooltip which will cause hideControl become true
  z-index: 6;
  position: relative;
  display: flex;
  align-items: center;
  &__num {
    margin-left: 4px;
    padding: 2px 3px;
    color: white;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    &--desktop {
      font-size: 16px;
      @media screen and (max-width: $smallPlayerMaxWidth) {
        font-size: 14px;
      }
    }
  }
}
</style>
