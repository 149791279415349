var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"PlayerSearch",class:{
      PlayerSearch__forSmallScreen: !_vm.isFullscreen,
      PlayerSearch__show: _vm.showPlayerSearch,
    }},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dark":"","color":"#1D1E21","height":"80px"}},[_c('button',{on:{"click":function($event){_vm.$emit('closePlayerSearch')}}},[_c('icon-base',{attrs:{"color":"#fff","height":"24","width":"24"}},[_c('d-icon-arrow-back')],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('div',{staticClass:"PlayerSearch__input"},[_c('div',{staticClass:"PlayerSearch__input-icon"},[_c('icon-base',{attrs:{"color":"#FFFFFF","height":"16","width":"16"}},[_c('d-icon-search')],1)],1),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInput),expression:"searchInput"}],ref:"inputSearch",staticClass:"search-input",domProps:{"value":(_vm.searchInput)},on:{"keyup":function($event){_vm.debounceSearchByText()},"input":function($event){if($event.target.composing){ return; }_vm.searchInput=$event.target.value}}}),_vm._v(" "),(_vm.searchInput)?_c('button',{staticClass:"PlayerSearch__input--remove",on:{"click":function($event){_vm.clearSearch()}}},[_c('icon-base',{attrs:{"color":"white","height":"12","width":"12"}},[_c('d-icon-close-light')],1)],1):_vm._e()]),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),(_vm.showKeyterms)?_c('div',{staticClass:"PlayerSearch__portraitTitle mobile-only"},[_vm._v("\n      "+_vm._s(_vm.$t("player.suggestedKeyterms"))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.showKeyterms)?_c('div',{staticClass:"PlayerSearch__keyterms"},[_c('div',{staticClass:"PlayerSearch__keyterms--title"},[_vm._v("\n        "+_vm._s(_vm.$t("player.suggestedKeyterms"))+"\n      ")]),_vm._v(" "),_vm._l((_vm.workflow.terms),function(term,idx){return _c('button',{key:idx,staticClass:"PlayerSearch__keyterms--keyterm",class:{
          'PlayerSearch__keyterms--selectedKeyterm': _vm.selectedKeyterm === term,
        },on:{"click":function($event){_vm.onclickKeyterms(term)}}},[_vm._v("\n        "+_vm._s(term)+"\n      ")])})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"PlayerSearch__results",class:{
        'PlayerSearch__results--fullScreen': !_vm.showKeyterms,
      }},[_c('d-enter-search',{attrs:{"showEnterSearch":_vm.searchInput === ''}}),_vm._v(" "),(_vm.searchResults.length > 0 && _vm.searchInput !== '')?_c('div',{staticClass:"PlayerSearch__results--wrap"},[(_vm.totalResultsNum <= 1)?_c('h3',{domProps:{"innerHTML":_vm._s(_vm.$t('explorer.resultFor', { num: _vm.totalResultsNum, input: this.searchInput }))}}):_c('h3',{domProps:{"innerHTML":_vm._s(_vm.$t('explorer.resultsFor', { num: _vm.totalResultsNum, input: this.searchInput }))}}),_vm._v(" "),_vm._l((_vm.searchResults),function(result,idx){return _c('div',{key:idx,staticClass:"result__step"},[_c('div',[_c('span',{staticClass:"result__step--title",on:{"click":function($event){_vm.jumpToSearchResult({ stepNumber: result.stepNumber, startTime: 0 })}}},[_c('span',{staticClass:"text-capitalize"},[_vm._v("\n                "+_vm._s(_vm.$t("all.step"))+"\n                "+_vm._s(_vm.getStepNum(result.stepNumber))+".\n              ")]),_vm._v("\n              "+_vm._s(_vm.getStepDisplayTitle(result.step))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"result__step--row"},_vm._l((result.sentenceResults),function(sentence,idx){return _c('div',{key:idx + sentence.startTime,staticClass:"result__card"},[_c('div',{staticClass:"result__card--img",on:{"click":function($event){_vm.jumpToSearchResult({ stepNumber: result.stepNumber, startTime: sentence.startTime })}}},[(_vm.isSearching)?_c('div',{staticClass:"result__card--progress"},[_c('v-progress-circular',{attrs:{"size":48,"width":2,"indeterminate":"","color":"grey darken-3"}})],1):_c('d-lazy-image',{attrs:{"aspect-ratio":16 / 9,"src":sentence.poster}})],1),_vm._v(" "),_c('div',{staticClass:"result__card--sentence",domProps:{"innerHTML":_vm._s(sentence.sentenceHtml)}}),_vm._v(" "),_c('div',{staticClass:"result__card--time"},[_vm._v(_vm._s(_vm.getTime(sentence.startTime)))])])}))])})],2):_c('d-no-result',{attrs:{"showNoResult":!_vm.isSearching && _vm.searchResults.length === 0 && _vm.searchInput !== ''}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }