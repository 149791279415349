<template>
  <div
    class="PlayerStepCard"
    :class="{
      'PlayerStepCard--done': isDone,
      'PlayerStepCard--current': isCurrentStep,
      'PlayerStepCard--sm': isSmallCard,
    }"
  >
    <div class="PlayerStepCard__poster">
      <d-image :aspect-ratio="16 / 9" :src="step.poster"></d-image>
      <div
        class="PlayerStepCard__poster--progress"
        :class="{
          'PlayerStepCard__poster--progressDark': isCurrentStep || isDone,
          'PlayerStepCard__poster--progressStroke': stepProgress > 0,
        }"
      >
        <div>
          <v-progress-circular
            rotate="-90"
            size="32"
            width="2"
            :color="isDone ? '#9397A6' : '#4689F3'"
            :value="stepProgress"
          >
            <span class="PlayerStepCard__poster--stepNum">{{ stepNum }}</span>
          </v-progress-circular>
        </div>
      </div>
    </div>
    <div class="PlayerStepCard__info">
      <div class="PlayerStepCard__info--title" :class="{ 'PlayerStepCard__info--titleDone': isDone }">
        {{ stepTitle }}
      </div>
      <div class="PlayerStepCard__info--bottom">
        <span class="PlayerStepCard__info--icon">
          <font-awesome-icon v-if="isCurrentStep" :icon="['fas', 'play']" class="text-primary" />
          <font-awesome-icon v-else-if="isDone" :icon="['fas', 'check']" class="text-primary" />
          {{ videoDuration }}
        </span>
        <button v-if="attachmentNum > 0" class="PlayerStepCard__info--icon" @click.stop="openAttachments">
          <font-awesome-icon :icon="['fas', 'paperclip']" :class="{ 'text-primary': isCurrentStep }" />
          <span>{{ attachmentNum }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DImage from "@/components/ui_components/DImage.vue";
import { getFormattedVideoDuration, getNumberWithZero } from "@/js/common/formatText.js";
import { getDisplayTitle } from "@/js/video-player/title-display.js";
import { mapActions } from "vuex";

export default {
  name: "PlayerStepCard",
  components: { DImage },
  props: {
    step: {
      type: Object,
      require: true,
    },
    isCurrentStep: Boolean,
    isSmallCard: Boolean,
    index: Number,
  },
  methods: {
    ...mapActions("workflowPlayer", ["setSelectedAttachmentStep"]),
    openAttachments() {
      let attachmentStep = this.step;
      attachmentStep["stepIndex"] = this.index;
      this.setSelectedAttachmentStep({ step: attachmentStep });
    },
  },
  computed: {
    stepTitle() {
      const displayLang = this.$i18n.locale;
      return getDisplayTitle(this.step, displayLang);
    },
    videoDuration() {
      return getFormattedVideoDuration({ videoDuration: this.step.duration });
    },
    attachmentNum() {
      let num = 0;
      if (!this.step.attachments) {
        return getNumberWithZero({ num: num });
      }
      Object.values(this.step.attachments).forEach((attachment) => {
        num = num + attachment.length;
      });
      return getNumberWithZero({ num: num });
    },
    stepNum() {
      return getNumberWithZero({ num: this.index + 1 });
    },
    isDone() {
      return false;
      // TODO: step progress will be add in next version
      // return this.stepProgress >= 100;
    },
    stepProgress() {
      return 0;
      // TODO: step progress will be add in next version
      // if (!this.step.progress) {
      //   return 0;
      // } else {
      //   return this.step.progress;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
$posterWidth: 114px;
.PlayerStepCard {
  --card-border-radius: 8px;
  --card-gap: 16px;
  --card-padding: 8px;
  --info-title-font-size: 16px;
  --info-bottom-font-size: 14px;
  --info-bottom-line-height: 14px;
  --info-height: 64px;
  --info-gap: 0;
  --info-line-clamp: 2;
  --info-icon-gap: 4px;
  --poster-display: block;

  width: 100%;
  padding: var(--card-padding);
  margin-bottom: var(--card-gap);
  border-radius: var(--card-border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  &--sm {
    --card-border-radius: 4px;
    --card-gap: 8px;
    --card-padding: 4px 8px;
    --info-title-font-size: 12px;
    --info-bottom-font-size: 10px;
    --info-bottom-line-height: 10px;
    --info-height: auto;
    --info-gap: 8px;
    --info-line-clamp: 1;
    --info-icon-gap: 2px;
    --poster-display: none;
  }
  &:hover {
    background: #28292e;
  }
  &--done {
    opacity: 0.5;
  }
  &--current {
    opacity: 1;
    background: #1d1e21;
    border: 1px solid #33353a;
  }
  &__poster {
    display: var(--poster-display);
    position: relative;
    min-width: $posterWidth;
    height: 64px;
    background-color: #0000004d;
    border-radius: 8px;
    overflow: hidden;
    &--progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      ::v-deep .v-progress-circular__underlay {
        fill: rgba(18, 19, 20, 0.6);
        stroke: rgb(144, 144, 144, 0);
      }
    }
    &--progressDark {
      background: linear-gradient(0deg, rgba(18, 19, 20, 0.6), rgba(18, 19, 20, 0.6));
      mix-blend-mode: normal;
    }
    &--progressStroke {
      ::v-deep .v-progress-circular__underlay {
        fill: rgba(18, 19, 20, 0.6);
        stroke: rgb(144, 144, 144);
      }
    }
    &--stepNum {
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }
  }
  &__info {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: var(--info-height);
    width: 100%;
    gap: var(--info-gap);
    &--title {
      font-size: var(--info-title-font-size);
      line-height: normal;
      color: #ffffff;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: var(--info-line-clamp);
      -webkit-box-orient: vertical;
    }
    &--titleDone {
      text-decoration: line-through;
    }
    &--bottom {
      width: 100%;
      font-size: var(--info-bottom-font-size);
      line-height: var(--info-bottom-line-height);
      color: var(--dGrey6-color);
      display: flex;
      justify-content: space-between;
    }
    &--icon {
      display: flex;
      gap: var(--info-icon-gap);
    }
  }
}
</style>
